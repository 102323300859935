.card 
    position: relative
    &__container 
        position: relative
        height: 340px
        border-radius: 10px
        overflow: hidden
        @media screen and (max-width: 376px)
            height: 255px
        &:before 
            content: ''
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            background: linear-gradient(180deg,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%)
        &__img 
            width: 100%
            height: 100%
            object-fit: cover
    &__text 
        position: absolute
        left: 20px
        right: 20px
        bottom: 20px
        font-size: 18px
        color: #fff
        line-height: 142.6%
 
.img-about
    @media screen and (max-width: 450px)
        height: 223px!important
.banner
    position: relative
    margin: 0 6.9% 0 6.9%
    @media screen and (max-width: 650px)
        margin: 0 20px 0 20px
    &__container 
        position: relative
        overflow: hidden
        height: 227px
        border-radius: 25px
        @media screen and (max-width: 450px)
            height: 111px
        &:before 
            content: ''
            position: absolute
            background: #000
            opacity: 0.3
            top: 0
            left: 0
            right: 0
            bottom: 0
        &__img 
            width: 100%
            height: 100%
            object-fit: cover
    &__paragraph 
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: flex
        align-items: center
        justify-content: center
        font-size: 3em
        color: #fff
        @media screen and (max-width: 850px)
            font-size: 36px
        @media screen and (max-width: 600px)
            font-size: 30px
        @media screen and (max-width: 500px)
            font-size: 24px
        @media screen and (max-width: 400px)
            display: initial
            font-size: 23px
            top: 31px
            left: 20px
            right: 102px
.footer
    background-color: #000
    color: #fff
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    height: 180px
    margin-top: 50px
    @media screen and (max-width: 450px)
        height: 209px
        margin-top: 27px
    &__img
        margin: 66px 0 29px 0
    &__paragraph 
        margin-bottom: 29px
        @media screen and (max-width: 450px)
            font-size: 12px
            text-align: center
            width: 134px
            height: 46px
            margin-bottom: 58px
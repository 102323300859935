.error 
    max-width: 1440px
    margin: auto
    display: flex
    flex-direction: column
    align-items: center
    color: #ff6060
    margin-top: 144px
    @media screen and (max-width: 400px)
        margin-top: 86px
    &__title 
        font-weight: 700
        font-size: 288px
        margin-bottom: 139px
        @media screen and (max-width: 600px)
            font-size: 96px
            margin-bottom: 113px
    &__paragraph 
        text-align: center
        line-height: 142.6%
        margin-bottom: 139px
        font-size: 36px
        @media screen and (max-width: 600px)
            width: 260px
            font-size: 18px
        @media screen and (max-width: 400px)
            margin-bottom: 113px
    &__link 
        color: #ff6060
        margin-bottom: 86px
        font-size: 18px
        @media screen and (max-width: 600px)
            font-size: 14px
.housing__identityRate
    display: flex
    flex-direction: column
    @media screen and (max-width: 775px)
        flex-direction: row-reverse
        justify-content: space-between
        align-items: center
        @media screen and (max-width: 600px)
    &__identity
        display: flex
        justify-content: flex-end
        margin-bottom: 27px
        @media screen and (max-width: 775px)
            margin: 0
        &__name
            width: 93px
            padding : 13px 10px 0 0
            font-size: 18px
            text-align: right
            color: #FF6060
            @media screen and (max-width: 650px)
                width: 85px
                font-size: 15px
            @media screen and (max-width: 450px)
                width: 68px
                font-size: 12px
        &__img
            width: 64px
            height: 64px
            border-radius: 30px
            @media screen and (max-width: 650px)
                width: 48px
                height: 48px
    &__rate
        display: flex
        &__img
            height: 24px
            margin-left: 15px
            @media screen and (max-width: 650px)
                height: 20px
            @media screen and (max-width: 400px)
                height: 14px
.header 
    display: flex
    justify-content: space-between
    align-items: center
    margin: 45px 6.9% 50px 6.9%
    @media screen and (max-width: 430px)
        margin: 20px 5.3% 27px 5.3%
    @media screen and (max-width: 375px)
    &__logo img
        @media screen and (max-width: 700px)
            height: 47px
    &__navbar 
        list-style-type: none
        font-size: 24px
        display: flex
        @media screen and (max-width: 600px)
            font-size: 12px
            text-transform : uppercase
        & .active
            text-decoration: underline
        &__link 
            color: #ff6060
            margin-left: 45px
            display: block
            @media screen and (max-width: 600px)
                margin-left: 20px

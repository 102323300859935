.housing__banner 
    position: relative
    margin: 0 6.9% 30px 6.9%
    @media screen and (max-width: 376px)
        margin: 0 20px 15px 20px
    &__arrowNext 
        position: absolute
        top: 40%
        right: 20px
        @media screen and (max-width: 450px)
            width: 12px
            height: 20px
            top: 50%
            right: 6px
    &__arrowPrev
        position: absolute
        top: 40%
        left: 20px
        @media screen and (max-width: 450px)
            width: 12px
            height: 20px
            top: 50%
            left: 6px
    &__counter
        position: absolute
        bottom: 6%
        left: 49%
        color: #fff
        font-size: 18px
    &__slide
        &__img 
            height: 415px
            width: 100%
            object-fit: cover
            border-radius: 25px
            @media screen and (max-width: 450px)
                width: 100%
                height: 255px
    &__slide.inactive
    &__slide.active
        width: 100%
        
.gallery
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 50px
  margin: 43px 6.9% 0 6.9%
  padding: 56px 50px
  border-radius: 25px
  background: #f6f6f6
  @media screen and (max-width: 1000px)
    grid-template-columns: 1fr 1fr
  @media screen and (max-width: 650px)
    grid-template: none
    grid-template-rows: 1fr
    gap: 20px
    margin: 22px 20px 0 20px
    padding: 0
    background: none